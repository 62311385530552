.allcalendar {
    margin-top: 1rem;
    font-family: 'Inter', sans-serif !important;
}

.allcalendar .button-action {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 2rem;
}

.allcalendar .button-action .button-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.allcalendar .button-action .title {
    font-weight: normal;
    font-size: 1.5rem;
    color: var(--color-active);
    text-align: center;
    display: flex;
    flex: 1;
}

.allcalendar .button-action .button-wrapper .button-group-wrapper {
    margin-bottom: 1rem;
}

.allcalendar .button-action .button-wrapper .button-group-wrapper button {
    border: none;
    padding: .7rem 1rem;
    cursor: pointer;
}

.allcalendar .button-action .button-wrapper .button-group-wrapper button.button-primary {
    background-color: var(--color-secondary);
    color: #fff;
}

.allcalendar .button-action .button-wrapper .button-group-wrapper button.button-primary.active {
    background-color: var(--color-primary);
    color: #fff;
}

.allcalendar .button-action .button-wrapper .button-group-wrapper button.button-secondary:not(:first-child) {
    border-left: none;
}

.allcalendar .button-action .button-wrapper .button-group-wrapper button:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.allcalendar .button-action .button-wrapper .button-group-wrapper button:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.allcalendar .button-action .button-wrapper .button-group-wrapper button.button-secondary {
    background-color: #fff;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
}

.allcalendar .button-action .button-wrapper .button-group-wrapper button > i {
    margin: 0px .5rem;
}