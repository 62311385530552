.dashboard {
  padding: 2% 3%;
  display: flex;
  width: 100%;
  font-family: 'Inter', sans-serif;
  flex-direction: column;
  background-color: transparent;
  color: white !important;
}

.overview-title {
  color: white !important;
}

.dashboard-header {
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--color-active);
}

.dashboard-header .dashboard-organization {
  min-width: 300px;
}

.dashboard-header .dashboard-organization span {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 1.5em;
  color: var(--color-active);
  margin-bottom: 0.5rem;
}

.dashboard-header .dashboard-service {
  min-width: 200px;
  text-align: center;
}

.dashboard-header .dashboard-service span {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 1.5em;
  color: var(--color-active);
  margin-bottom: 0.5rem;
}

.dashboard .dashboard-span:hover {
  cursor: pointer;
}

.dashboard-header .dashboard-directory {
  min-width: 200px;
  text-align: center;
}

.dashboard-header .dashboard-directory span {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 1.5em;
  color: var(--color-active);
  margin-bottom: 0.5rem;
}

.dashboard .dashboard-table {
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;
  background: #FFFFFF;
  box-shadow: 0px 12px 40px rgb(0 0 0 / 6%);
  border-radius: 6px;
  color: #9E9E9E;
}

.dashboard .dashboard-table>.dashboard-table-header {
  display: flex;
  align-items: center;
  background-color: var(--color-bg-white-blueish);
  padding: 1rem;
}

.dashboard .dashboard-table>.dashboard-table-header>p.dashboard-header-title {
  font-size: .8rem;
  margin-right: 0.5rem;
  font-weight: 400;
  margin-bottom: 0px;
  color: var(--color-text-placeholder);
}

.dashboard .dashboard-table>.dashboard-table-header>p.dashboard-header-subtitle {
  font-size: .8rem;
  margin-right: 0.5rem;
  font-weight: 400;
  color: var(--color-black);
  margin: 0px;
}

.dashboard .page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard .page-header>.page-search {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--color-border);
  padding: .5rem .8rem;
  border-radius: 5px;
}

.dashboard .page-header>.page-search>i {
  margin-right: .25rem;
  color: var(--color-text-placeholder);
  font-size: 1.2rem;
}

.dashboard .page-header>.page-search>input {
  border: none;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: var(--color-black);
  font-size: .8rem;
  min-width: 160px;

}

.dashboard .page-header>.page-search>input::placeholder {
  color: var(--color-text-placeholder);
}

.dashboard .page-header>.page-search>input:focus {
  outline: none;
}

.dashboard .page-header>.page-title {
  font-weight: 700;
  color: var(--color-black);
  margin: 1.5rem 0rem;
  font-size: 1.6rem;
}

.dashboard .dashboard-table>.title {
  font-size: 1.2em;
  color: var(--color-black);
  margin-bottom: 0px;
  background-color: #fff;
  padding: 1rem;
  border-bottom: 1px solid var(--color-stroke-dark);
}

.dashboard .dashboard-table>.dashboard-row-wrapper>.dashboard-row {
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: hsl(0, 0%, 35%);
  font-weight: 500;
  padding: 1rem;
  background-color: var(--color-bg-primary);
}

.dashboard .dashboard-table>.dashboard-row-wrapper>.dashboard-row:not(:last-child) {
  border-bottom: 1px solid var(--color-stroke-dark);
}

.dashboard .dashboard-table>.dashboard-row-wrapper>.dashboard-row>a {
  display: flex;
  align-items: center;
}

.dashboard .dashboard-table>.dashboard-row-wrapper>.dashboard-row>a>svg {
  margin-right: 0.5rem;
}

.dashboard .dashboard-table>.dashboard-row-wrapper>.dashboard-row>a>span {
  margin-right: .5rem;
}

.dashboard .dashboard-table>.dashboard-row-wrapper>.dashboard-row>a>span,
.dashboard .dashboard-table>.dashboard-row-wrapper>.dashboard-row>a>i {
  color: var(--color-text-primary);
  font-size: 1rem;
  font-weight: 400;
}

/* .dashboard
  .dashboard-table
  > .dashboard-row-wrapper
  > .dashboard-row:not(:last-child) {
  border-bottom: 1px solid var(--color-stroke);
} */

.dashboard .dashboard-table>.dashboard-row-wrapper>.dashboard-row .ant-select {
  max-width: 300px;
}

.dashboard .dashboard-table>.dashboard-row-wrapper>.dashboard-row>.dashboard-span {
  width: 300px;
}

.dashboard .dashboard-table>.dashboard-row-wrapper>.dashboard-row>a {
  color: inherit;
}

.dashboard .dashboard-table>.dashboard-row-wrapper>.dashboard-row>ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
}

.dashboard .dashboard-table>.dashboard-row-wrapper>.dashboard-row>.service-list>li {
  margin: 0px 0.5rem;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  border-radius: 5px;
  color: var(--color-black);
}

.dashboard .dashboard-table>.dashboard-row-wrapper>.dashboard-row>.service-list>li:hover {
  background-color: var(--color-stroke);
  color: var(--color-primary);
}

.service-list>li>i {
  margin-right: 0.25rem;
}

.service-list>li .ant-dropdown-trigger>span {
  margin-left: 0.25rem;
  color: var(--color-black);
}

.service-list>li .ant-dropdown-trigger:hover>span {
  color: var(--color-primary);
}

.dashboard .dashboard-table>.dashboard-row-wrapper>.dashboard-row>.playlist-type {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 1em;
  color: var(--color-active);
  min-width: 200px;
  border: none;
  text-align: center;
}

.dashboard .dashboard-table>.dashboard-menu-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 0 1rem;
}

.dashboard .dashboard-table>.dashboard-menu-header>i {
  padding-top: 0.3rem;
}

.dashboard .dashboard-table:hover {
  box-shadow: 10px 3px 22px -1px rgb(0 0 0 / 18%);
  -webkit-box-shadow: 10px 3px 22px -1px rgb(0 0 0 / 18%);
  -moz-box-shadow: 10px 3px 22px -1px rgba(0, 0, 0, 0.18);
}



.dashboard .dashboard-table>.dashboard-row-wrapper>.dashboard-row>ul>li {
  margin: 0;
  cursor: pointer;
  color: var(--color-secondary);
  display: flex;
  column-gap: 0.2rem;
  align-items: center;
  min-width: max-content;
  padding: 0.3rem 0.5rem;
}

.dashboard .dashboard-table>.dashboard-row-wrapper>.dashboard-row>ul>li:hover {
  color: #fff;
}