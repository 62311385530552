.full-layout {
    overflow: hidden;
    position: sticky;
}

.full-layout-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 2rem 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0
}

.full-layout .full-layout-loading {
    position: absolute;
    z-index: 9;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}