.user {
  padding: 2% 3%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
}

.user .user-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--color-stroke);
  padding: 1rem 0px;
}

.user .user-header > .button-primary {
  background-color: var(--color-primary);
  border: none;
  color: #fff;
  padding: 0px 1rem;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user .user-header > h1 {
  font-size: 1.5em;
  font-weight: 600;
  color: var(--color-active);
}

.user .user-breadcrumb {
  margin: 1rem 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user .user-breadcrumb > ul > li > a {
  color: var(--color-active);
}

.user .user-breadcrumb > ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.user .user-breadcrumb > ul > li {
  display: inline-block;
  color: var(--color-secondary);
  margin-right: 1rem;
}

.user .user-breadcrumb > ul > li:not(:last-child)::after {
  content: '/';
  margin-left: 1rem;
  color: var(--color);
}

.user .user-content {
  margin: 1.5rem 0px;
  display: flex;
  justify-content: flex-start;
}
