$name-color: #c6538c;
$desc-color: #171C26;
$time-color: #464F60;
$action-color: #A0ACB7;
$table-header-color: rgba(247, 249, 252, 0.8);
$primary-color: #64D5D2;
$btn-action: #A0ACB7;


.organizations {
  font-family: 'Roboto', sans-serif;
  padding: 2% 2.5rem;
  width: 100%;
  color: #404040;

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0px;
    border-bottom: 1px solid var(--color-stroke);

    .button-primary {
      border: none;
      height: 100%;
      padding: 0.5rem 1.5rem;
      color: #fff;
      background-color: $primary-color;
      border-radius: 5px;
    }
  }

  &__title {
    font-size: 1.5em !important;
    font-weight: 600 !important;
    color: var(--color-active) !important;
  }

  &__nav {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
  }

  &__search {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--color-border);
    padding: .5rem .8rem;
    border-radius: 5px;

    i {
      font-size: 1rem;
    }

    input {
      outline: none;
      border: none;
      background-color: transparent;
      padding-left: 1rem;
      color: var(--color-active);

      &::placeholder {
        color: var(--color-secondary);
      }
    }
  }

  &__breadcrumb {
    padding: 0.5rem 2rem 0.5rem 1rem;
    color: var(--color-secondary);
    cursor: pointer;
  }

  &__table {
    th {
      background-color: var(--color-navbar) !important;
      color: var(--color-black);
    }

  }

  &__actions {
    display: flex;
    justify-content: center;
    column-gap: 0.5rem;
  }
  
  .btn-text {
    margin-left: 0;
    margin-top: -0.05em;
  }

  .btn {
    color: $btn-action;
    background-color: transparent;
    outline: none;
    border: none;
    display: flex;

    &:hover {
      cursor: pointer;
    }

    &--edit {
      &:hover {
        color: $primary-color;
      }
    }

    &--delete {
      &:hover {
        color: red;
      }
    }
  }
}