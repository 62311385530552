.media {
  padding: 2% 3%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
}

.media .media-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 1rem;
}

.media .media-header > .button-primary {
  background-color: var(--color-primary);
  border: none;
  color: #fff;
  padding: 0px 1rem;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.media .media-header > h1 {
  font-size: 1.5em;
  color: var(--color-text-black);
  font-weight: 700;
  margin: 0px;
}

.media .media-breadcrumb {
  margin: 1rem 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.media .media-breadcrumb > ul > li > a {
  color: var(--color-active);
}

.media .media-breadcrumb > ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.media .media-breadcrumb > ul > li {
  display: inline-block;
  color: var(--color-secondary);
  margin-right: 1rem;
}

.media .media-breadcrumb > ul > li:not(:last-child)::after {
  content: '/';
  margin-left: 1rem;
  color: var(--color);
}

.media .media-content {
  margin: 1.5rem 0px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.media .media-breadcrumb .button-secondary {
  background-color: #fff;
  border: 1px solid var(--color-primary);
  border-radius: 10px;
  color: var(--color-primary);
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.media .media-breadcrumb .button-secondary > i {
  margin-right: 0.5rem;
}