.media-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;

  /* First Line: Media Details and Back Button */
  .media-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 2rem;
    padding: 0 1.2rem;

    .media-details {
      h2 {
        font-size: 1.5rem;
        font-weight: 600;
        color: #404040;
        margin: 0 0 0.5rem 0;
      }

      p {
        margin: 0.2rem 0;
        font-size: 0.9rem;
        color: var(--color-secondary);
      }
    }

    .button-primary {
      border: 1px solid #e0e0e0;
      padding: 0.5rem 1.5rem;
      color: black;
      background-color: white;
      border-radius: 5px;
      display: flex;
      align-items: center;
    }
  }

  /* Second Line: Dropdown, Date Picker, and Export Button */
  .header-controls {
    display: flex;
    justify-content: space-between; // Push export button to the end
    align-items: center;
    gap: 1rem;
    padding: 0 0.8rem;

    .filter-wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;

      .playlist-wrapper {
        display: flex;
        align-items: center;
        background-color: #fff;
        border: 1px solid var(--color-border);
        border-radius: 0.35rem;
        padding: 0.5rem 0.7rem;
        cursor: pointer;

        .playlist-selection {
          display: flex;
          align-items: center;

          span {
            margin-left: 0.5rem;
            font-size: 0.9rem;
            font-weight: 500;
            color: var(--color-black);
            white-space: nowrap; // Prevent text from wrapping
            transition: color 0.2s ease-in-out;

            /* Style when no period is selected */
            &:empty::before {
              content: 'Select Period';
              color: var(--color-secondary); // Placeholder text color
            }
          }
        }

        i {
          margin-left: 0.5rem;
          transition: transform 0.2s ease-in-out;
        }

        &.playlist-open i {
          transform: rotate(180deg);
        }

        /* Dropdown Styling */
        .playlist-dropdown {
          position: absolute;
          top: 100%; // Position below the playlist-wrapper
          left: 0;
          width: 100%;
          background-color: #fff;
          border: 1px solid var(--color-border);
          border-radius: 0.35rem;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
          z-index: 10;
          margin-top: 0.5rem;
          overflow: hidden;

          div {
            padding: 0.5rem 0.7rem;
            font-size: 0.9rem;
            font-weight: 500;
            color: var(--color-black);
            cursor: pointer;
            transition: background-color 0.2s ease-in-out;

            &:hover {
              background-color: var(--color-bg-hover); // Hover background color
            }
          }
        }
      }

      .ant-picker {
        border-color: var(--color-light-grey);
        border-radius: 0.5rem;
        padding: 0.6rem 1rem;
      }
    }

    .analytic-header-right {
      .btn-outline-primary {
        display: flex;
        align-items: center;
        border: 1px solid var(--primary-button);
        border-radius: 0.35rem;
        padding: 0.5rem 1rem;
        color: var(--primary-button);
        font-weight: 600;
        background-color: transparent;
        cursor: pointer;

        svg {
          margin-right: 0.5rem;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.analytics-overview {
  padding: 0 1.2rem;

  .title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #404040;
    margin-bottom: 1.5rem; // Add space below the title
  }

  .analytics-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    margin-bottom: 1rem;

    th,
    td {
      padding: 1rem;
      text-align: center; // Center-align text for better readability
    }

    th {
      font-size: 1rem;
      font-weight: 600;
      color: #21486c;
      border-bottom: 2px solid #f0f0f0;
      background-color: #edf1f7;
    }

    td {
      font-size: 1rem;
      font-weight: 700;
      color: #21486c;
      background-color: #fbfbfb;
    }

    tr:last-child td {
      border-bottom: none; // Remove border from the last row
    }
  }
}

.distribution-grid {
  display: grid;
  grid-template-columns: 2fr 1fr; // Changed to 2:1 ratio
  gap: 20px;
  margin: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; // Stack on mobile
  }

  .age-group-distribution,
  .gender-distribution {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    h3 {
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 500;
    }

    .chart-container {
      position: relative;
      height: 400px;
      width: 100%;
      min-height: 300px;
      max-height: 500px;

      canvas {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}

.dropdown {
  position: relative;
  display: inline-block;

  .dropdown-content {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 4px;
    margin-top: 4px;

    button {
      width: 100%;
      padding: 12px 16px;
      text-align: left;
      border: none;
      background: none;
      cursor: pointer;

      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
}
