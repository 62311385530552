.authlogs {
  font-family: 'Inter', sans-serif;
  padding: 2% 3%;
  width: 100%;
}

.authlogs .authlogs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.authlogs .authlogs-menu {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.authlogs .authlogs-dropdown {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.authlogs .authlogs-dropdown .child-dropdown {
  margin-right: 1rem ;
  width: max-content;
}

.authlogs .authlogs-download {
  display: flex;
}

.authlogs .authlogs-dropdown span {
  color: var(--color-active);
  font-size: 1em;
  font-weight: 500;
  margin-right: 0.5rem;
}


.authlogs .ant-picker {

  /* width: 12rem !important; */
  padding: 0.8rem 1rem;
  
  /* background-color: aqua; */
  border-color: var(--color-light-grey) !important;
  border-radius: 0.5rem !important;
  color: var(--color-active);
}

.authlogs .authlogs-dropdown .ant-select {
  display: inline-block;
  width: auto !important;
  /* background-color: aqua; */
  border-color: var(--color-black) !important;
  border-radius: 0.5rem !important;
  color: var(--color-active);
}

.authlogs .authlogs-dropdown .ant-select-selector {
  display: inline-block;
  height: 3rem !important;
  padding: 0.5rem 1.5rem !important;
  width: auto !important;
  font-weight: 600;
  font-size: 1rem ;
  background-color: transparent !important;
  color: var(--color-active);
}

.authlogs .authlogs-dropdown .ant-select-selector {
  display: inline-flex;
  height: 3rem !important;
  padding: 0.5rem 1.5rem !important;
  min-width: 12rem;
  width: auto !important;
  margin: 0 auto;
  align-items: center;
  font-weight: 600;
  font-size: 1rem ;
  background-color: transparent !important;
  color: var(--color-active);
}
.authlogs .authlogs-dropdown .ant-select-selection-item {
 display: flex;
 align-items: center;
}

.authlogs .search-bar, .authlogs .authlogs-menu > .authlogs-search .ant-picker {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--color-border) !important;
  border-radius: 0.25rem !important;
  font-family: 'Inter', sans-serif;
  font-size: .9rem;
  padding: 0.5rem 1rem;
  margin-right: 1rem
}

.authlogs .authlogs-menu > .authlogs-search .ant-picker.ant-picker-focused {
  border-color: var(--color-input-focus) !important;
  box-shadow: none !important;
}

.authlogs .search-bar input::placeholder {
  color: var(--color-text-light) !important;
}

.authlogs .search-bar input:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: var(--color-input-focus) !important;
}

.authlogs .dropdown-tree {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid var(--color-secondary);
  padding: 0.3rem 0.7rem;
  margin-right: 1rem;
  width: 18%;
}

.authlogs .search-bar i {
  font-size: 1.5em;
  color: var(--color-active);
}

.authlogs .search-bar input {
  outline: none;
  border: none;
  background-color: transparent;
  padding-left: 1rem;
  color: var(--color-active);
}

.authlogs .search-bar input::placeholder {
  color: var(--color-secondary) !important;
}

.authlogs .ant-pagination-options-size-changer.ant-select {
  width: 8rem !important;
}

.authlogs table th {
  background-color: var(--color-navbar) !important;
  color: var(--color-black);
}

.authlogs table .btn {
  color: hsla(0, 0%, 31%, 1);
  background-color: transparent;
  outline: none;
  border: none;
}

.authlogs table tr {
  font-size: 0.8rem;
}

.authlogs table td.ant-table-cell {
  padding: 0.5rem;
}

.authlogs table .btn i {
  margin-right: 0.2rem;
}

.authlogs table .btn:hover {
  cursor: pointer;
}

.authlogs table .btn--edit .btn--edit:hover {
  color: var(--color-primary);
}
.authlogs table .btn--access .btn--access:hover {
  color: green;
}
.authlogs table .btn--delete .btn--delete:hover {
  color: red;
}

.authlogs .authlogs-limit {
  margin-bottom: 1rem;
}

.authlogs .authlogs-date-picker {
  width: auto;
  height: max-content;
}


.authlogs .authlogs-search {
  display: flex;
  column-gap: 12px;
  align-items: center;
}
