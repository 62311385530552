.screen {
  min-height: 60vh;
}

.screen-content {
  display: flex;
}

.screen-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.screen-column:not(:last-child) {
  padding-right: 3rem;
}

.screen-title {
  color: var(--color-black);
  font-size: 1.2em;
  font-weight: 700;
}

.screen-table {
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem;
}
.screen-button-update {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
}

.screen-table > div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.screen-table > div > div:first-child {
  color: var(--color-active);
  font-weight: 600;
}

.screen-table > div > div:last-child {
  color: var(--color-black);
  font-weight: 600;
}

.screen-p {
  color: var(--color-black);
  font-weight: 600;
}

.screen-list > div:first-child {
  margin-top: 0px;
  padding-top: 0px;
}

.screen-list > div {
  margin: 1rem 0px;
  padding: 1rem 0px;
}

.screen-list > div:not(:last-child) {
  border-bottom: 1px solid var(--color-stroke);
}

.screen-justify {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.screen-shoot {
  position: relative;
  width: 100%;
  min-height: 200px;
  height: 100%;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-input);
  border-radius: 10px;
  border: 2px solid var(--color-border);
}

.screen-shoot > i {
  font-size: 3rem;
  color: var(--color-secondary);
}

.screen-button {
  border: none;
  background-color: var(--color-primary);
  color: #fff;
  padding: 0.5rem 0px;
  border-radius: 10px;
  cursor: pointer;
}

.install-button {
  border: none;
  background-color: var(--color-primary);
  color: #fff;
  padding: 0.5rem 1em;
  margin-right: 1em;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  font-weight: 600;
}

.install-button-outline {
  border: none;
  color: var(--color-primary);
  background-color: #fff;
  padding: 0.5rem 1em;
  margin-right: 1em;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  font-weight: 600;
}

.screen-button-loading {
  border: none;
  background-color: var(--color-primary);
  color: #fff;
  padding: 0.5rem 0px;
  border-radius: 10px;
  cursor: pointer;
}

.screen-button:hover {
  opacity: 0.8;
}

.install-button:hover {
  opacity: 0.8;
}

.screen-loading {
  width: 96%;
  background-color: var(--color-background);
  position: absolute;
  z-index: 2;
  height: 68%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-black);
  font-size: 1.5em;
  font-weight: 100;
}

.screen-not-connected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem;
}

.screen-not-connected > h1 {
  font-family: 'Inter', sans-serif;
  color: var(--color-text-black);
  font-weight: 700;
  font-size: .9rem;
  margin: 1rem 0px;
}

.screen-not-connected > button.btn-outline-primary {
  display: flex;
  align-items: center;
  border: 1px solid var(--primary-button);
  border-radius: 0.35rem;
  padding: .5rem 1rem;
  color: var(--primary-button);
  font-weight: 600;
  background-color: transparent;
  cursor: pointer;
}

.screen-not-connected > button.btn-outline-primary:hover {
  opacity: .6;
}

.ant-slider-track {
  background-color: var(--color-primary) !important;
}

.ant-slider-rail {
  background-color: rgb(99,212,210, 0.2) !important;
}

.ant-slider-handle.ant-tooltip-open {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.screen-shoot .camera-button {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border: 1px solid var(--color-primary);
  border-radius: 0.25rem;
  color: var(--primary-button);
  font-weight: 600;
  cursor: pointer;
}

.screen-shoot .camera-button:hover {
  opacity: .5;
}

.screen-shoot .camera-button > svg {
  margin-right: .5rem;
}
.screen-shoot .button-remove {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: var(--color-bg-pink);
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 0.25rem;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}