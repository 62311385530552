.reset-password {
  font-family: 'Rubik', sans-serif;
  height: auto;
  max-height: 100%;
  width: 35rem;
overflow: auto;
  background-color: #fff;
  padding: 2rem;
  box-shadow: 0px 12px 64px rgb(0 0 0 / 50%);
  border-radius: 0.5rem;
}

.reset-password .reset-password-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reset-password .reset-password-header img.login-logo {
  width: 7rem;
  margin-bottom: 2rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.reset-password .reset-password-header > .reset-password__title {
  color: var(--color-blue-1);
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.375rem;
}

.reset-password .reset-password-header > .reset-password__desc {
  color: var(--color-text-grey);
  margin-bottom: 2rem;
}

.reset-password .ant-alert-error {
  background-color: HSL(0, 84%, 95%) !important;
}

.reset-password .ant-alert-message {
  color: HSL(0, 83%, 55%) !important;
}

.reset-password > .reset-password__form {
  margin: 0.5rem 0 2.5rem 0;
}

.reset-password > .reset-password__form > label {
  color: var(--color-label);
  margin-bottom: 0.5rem;
  display: inline-block;
}

.reset-password > .reset-password__form > .reset-password__form-controls {
  position: relative;
  border: 1px solid #e0e0e0 !important;
  border-radius: 0.3rem;
  margin-bottom: 1rem;
  /* border-radius: 2px; */
}

.reset-password > .reset-password__form > .reset-password__form-controls.focus {
  border: 1px solid var(--color-border-active) !important; 
}


.reset-password > .reset-password__form > .error-login {
  border: 1px solid HSL(0, 83%, 55%) !important;
}

.reset-password > .reset-password__form > .reset-password__form:first-child {
  border-bottom: none !important;
}

.reset-password
  > .reset-password__form
  .reset-password__form-controls
  .reset-password__label {
  position: absolute;
  left: 4.1rem;
  top: 0.5rem;
  z-index: 9999;
  pointer-events: none;
  transform: translateY(1.37rem);
  transition: 0.3s all ease;
  color: var(--color-text-grey);
}

.reset-password
  > .reset-password__form
  .reset-password__form-controls
  .ant-input-affix-wrapper
  > input.ant-input:focus
  ~ .ant-input-suffix
  > .reset-password__label,
.reset-password
  > .reset-password__form
  .reset-password__form-controls
  .ant-input-affix-wrapper
  > input.ant-input:not(:focus):valid
  ~ .ant-input-suffix
  > .reset-password__label {
  transform: translateY(0) !important;
}

.reset-password
  > .reset-password__form
  > .reset-password__form-controls
  input.ant-input {
  border-radius: 0px !important;
  padding: 1.875rem 1.5675rem 1.875rem 1rem;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  background-color: #fff;
  border-radius: 2px !important;
  color: #000000;
}

.reset-password
  > .reset-password__form
  > .reset-password__form-controls
  input.ant-input::placeholder {
  color: var(--color-text-grey);
}

.reset-password
  > .reset-password__form
  > .reset-password__form-controls
  .ant-input-affix-wrapper {
    position: relative;
    padding: 0 1rem;
    font-size: 1rem !important;
    border: none;
    border-radius: 0.5rem;
    overflow: hidden;
    margin: 0px !important;
    box-shadow: 0px 12px 40px rgb(224 224 224 / 20%) !important;
}

.reset-password
  .reset-password__form
  > .reset-password__form-controls
  .ant-input-affix-wrapper-focused {
  outline: none !important;
}

.reset-password
  > .reset-password__form
  .reset-password__form-controls
  .ant-input-affix-wrapper::after {
  position: absolute;
  content: '';
  height: 100%;
  width: 0.375rem;
  left: 0;
  bottom: 0;
  background-color: transparent;
  transition: 0.5s all ease;
}

.reset-password
  > .reset-password__form
  .reset-password__form-controls
  .ant-input-affix-wrapper-focused::after {
  /* background-color: hsla(191, 89%, 64%, 1); */
}

.reset-password
  > .reset-password__form
  .reset-password__form-controls
  .ant-input-affix-wrapper
  > input {
  border: none !important;
}

.reset-password
  > .reset-password__form
  .reset-password__form-controls
  .ant-input-group-addon {
  background-color: #fff;
  color: hsla(0, 0%, 48%, 1);
  border: none !important;
  font-size: 1.079375rem !important;
  padding-right: 1.375rem !important;
}

.reset-password
  > .reset-password__form
  .reset-password__form-controls:last-child {
  margin-top: 4rem;
  border: none !important;
}

/* .login .form-wrapper > .form > .flex {
  display: flex;
  justify-content: space-between;
} */

.reset-password > .reset-password__form .reset-password__button {
  display: block;
  color: #fff;
  font-size: 1rem;
  padding: 0.8rem 0;
  width: 100%;
  height: 100%;
  font-weight: 700;
  background: #64D5D2;
  box-shadow: 0px 12px 12px rgb(83 215 245 / 10%);
  border: none;
  font-family: 'Inter', sans-serif;
  border-radius: 0.5rem;
  margin-top: 2rem;
}

.reset-password > .reset-password__form .reset-password__back {
  display: block;
  margin-top: 0.2rem;
  color: #64D5D2;
  font-size: 1rem;
  padding: 1rem 0;
  width: 100%;
  height: 100%;
  font-weight: 700;
  background: #fff;
  /* border: 2px solid #53d7f5; */
  border: none;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
}

.reset-password .reset-password__message-title {
  /* margin-bottom: 1.6875rem; */
  font-weight: 500;
  color: var(--color-active);
}
.reset-password .reset-password__message-invalid {
  font-weight: 400;
  display: flex;
  align-items: center;
}

.reset-password i {
  margin-right: 0.6875rem;
}

.reset-password .is-is-success {
  color: green;
}

.reset-password .is-is-danger {
  color: red;
}

.error-form {
  border-color: var(--color-danger) !important;
}
