.table-header {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 0 1rem 0;
}

.header-table {
  display: flex;
  justify-content: space-between;
}

.mr-5 {
  margin-right: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.space-justify-between {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 560px) {
  .table-header {
    justify-content: space-between;
  }
}

/*---------------*/
/***** Zoe *****/
/*---------------*/

figure.effect-zoe figcaption {
  width: 250px;
  height: 105px;
  top: 10px;
  bottom: 0px;
  padding: 5px;
  background: #ffffffcc;
  color: #3c4a50;
}

figure.effect-zoe h2 {
  float: left;
}

figure.effect-zoe figcaption > span {
  float: right;
  margin-top: 5px;
}

figure.effect-zoe figcaption > span {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 75%, 0);
  transform: translate3d(0, 60%, 0);
}

figure.effect-zoe figcaption > span::before {
  display: inline-block;
  padding: 8px 10px;
}

figure.effect-zoe h2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 230px;
}

figure.effect-zoe:hover figcaption,
figure.effect-zoe:hover h2,
figure.effect-zoe:hover figcaption > span {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-zoe:hover h2 {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

figure.effect-zoe:hover figcaption > span:nth-child(4) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

figure.effect-zoe:hover figcaption > span:nth-child(3) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

figure.effect-zoe:hover figcaption > span:nth-child(2) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.level {
  width: 98% !important;
}

.tableHeader input {
  max-width: 250px;
  margin-left: 15px;
}

.button-grid {
  display: grid;
  row-gap: 10px;
  column-gap: 10px;
  grid-template-rows: 100%;
  grid-template-columns: 20% 20% 20%;
}

.button-group-wrapper > .button-group:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none;
}

.button-group-wrapper > .button-group:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.button-group {
  border-radius: 0px;
  border: none;
  background-color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #e1e1e1;
}

.button-group:focus {
  outline: none;
}

.button-group:hover,
.button-group.button-group-active {
  background-color: #3273dc;
  color: #fff;
}

.modal-content.modal-sm {
  width: 320px !important;
}

.modal-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.header-text {
  font-weight: 600;
  color: #888;
}

.badge {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
  border-radius: 10px;
}

.rbc-event-custom:not(.rbc-event-allday) {
  width: 5px !important;
  border: none !important;
  padding: 0px !important;
  margin: 0px !important;
}

.rbc-event-custom .rbc-event-label {
  display: none !important;
}

.loading {
  display: flex;
  position: absolute;
  background-color: #ffffffde;
  z-index: 9;
  width: 100%;
  height: 83%;
  justify-content: center;
  align-items: center;
}

.loading > i {
  font-size: 2em;
  color: #3273dc;
}
