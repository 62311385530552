.main-title {
    display: flex;
    margin-bottom: 1rem;
    margin-top: 2.5rem;
    color: var(--color-text-gray-dark);
    text-transform: uppercase;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: .68rem;
    padding: 0 1rem;
    letter-spacing: .15rem;
}
