

.card-wrapper {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 32% 32% 32%;
  grid-template-rows: auto auto 1fr 1fr 1fr auto auto;
  justify-content: space-between;
}


.playlist-wrapper {
  display: flex;
  margin-left: 0.5rem;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  background-color: #fff;
  border-radius: 0.35rem;
  border: 1px solid var(--color-border);
  padding: 0.5rem 0.7rem;
  position: relative;
  cursor: pointer;
}

.playlist-wrapper > .playlist-dropdown {
  position: absolute;
  background-color: #fff;
  top: 2.8rem;
  box-shadow: 0px 6px 24px rgb(0 0 0 / 12%);
  border-radius: 0.25rem;
  width: 200px;
  left: 0;
  cursor: text !important;
}

.playlist-wrapper > .playlist-dropdown.playlist-dropdown-open {
  opacity: 1;
  z-index: 2;
  transition: opacity ease-in-out 250ms;
}

.playlist-wrapper > .playlist-dropdown.playlist-dropdown-closed {
  opacity: 0;
  z-index: -2;
  transition: opacity ease-in-out 250ms, z-index 250ms ease-in-out 150ms;
}

.playlist-wrapper > .playlist-dropdown > ul {
  margin: 0px;
  max-height: 300px;
  overflow-y: auto;
}

.playlist-wrapper > .playlist-dropdown > ul > li.divider {
  border-bottom: 1px solid var(--color-stroke-dark);
  margin: .5rem 1rem;
  padding: 0px;
}

.playlist-wrapper > .playlist-dropdown > ul > li.header {
  padding: 0.5rem 1rem;
  background-color: var(--color-bg-white-blueish);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-active);
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.playlist-wrapper > .playlist-dropdown > ul > li.header > i {
  margin-right: .5rem;
}

.playlist-wrapper > .playlist-dropdown > ul > li {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  color: var(--color-black);
  font-weight: 600;
  font-size: .8rem;
}

.playlist-wrapper > .playlist-dropdown > ul > li.playlist-button {
  background-color: var(--primary-button);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 1rem 1rem 1rem;
  border-radius: 0.5rem;
  color: #fff;
  cursor: pointer;
}

.playlist-wrapper > .playlist-dropdown > ul > li > div.radio,
.playlist-wrapper > .playlist-dropdown > ul > li > div.playlist-filter > .playlist-filter-radio > div.radio {
  width: 15px;
  height: 15px;
  background-color: var(--color-bg-radio);
  margin-right: 0.5rem;
  border-radius: 15px;
  border: 1px solid var(--color-border-radio);
  display: flex;
  align-items: center;
  justify-content: center;
}

.playlist-wrapper > .playlist-dropdown > ul > li > div.radio.active,
.playlist-wrapper > .playlist-dropdown > ul > li > div.playlist-filter > .playlist-filter-radio > div.radio.active {
  border-color: var(--primary-button);
}

.playlist-wrapper > .playlist-dropdown > ul > li > div.radio > div.radio-checked,
.playlist-wrapper > .playlist-dropdown > ul > li > div.playlist-filter > .playlist-filter-radio > div.radio > div.radio-checked {
  width: 8px;
  height: 8px;
  background-color: var(--primary-button);
  border-radius: 8px;
}


.playlist-wrapper > .playlist-dropdown > ul > li > div.playlist-filter {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.playlist-wrapper > .playlist-dropdown > ul > li > div.playlist-filter > .playlist-filter-radio {
  display: flex;
  align-items: center;
}

.playlist-wrapper > .playlist-dropdown > ul > li > div.playlist-filter > button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.playlist-wrapper i.playlist-open {
  transform: rotate(180deg);
  transition: all 250ms ease-in-out;
}
.playlist-wrapper i.playlist-closed {
  transform: rotate(0deg);
  transition: all 250ms ease-in-out;
}

.playlist-wrapper .playlist-selection {
  display: flex;
  align-items: center;
}

.playlist-wrapper .playlist-selection > span {
  margin-left: 0.25rem;
  font-weight: 600;
  color: var(--color-black);
}
.data-card {
  display: flex;
  flex: 0 0 100%;
  background-color: #fff;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--color-stroke);
  border-radius: 5px;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  height: 100%;
  flex-direction:column;
  flex:1;
}

.data-card:hover,
.data-card.active {
  box-shadow: 10px 3px 22px -1px rgb(0 0 0 / 18%);
  -webkit-box-shadow: 10px 3px 22px -1px rgb(0 0 0 / 18%);
  -moz-box-shadow: 10px 3px 22px -1px rgba(0, 0, 0, 0.18);
}

.data-card div {
  display: flex;
  border-bottom: none !important;
}

.card-data-header {
  background: var(--header-color) !important;
  display: flex;
  width: 100%;
  font-weight: 400;
  font-size: 10px;
  border-radius: 5px 5px 0 0;
  
  justify-content: space-between;
  color: var(--color-text-placeholder);
}

.card-data-header .card-info {
  padding: 0.625rem 0rem 0.625rem 0rem;
}
.card-data-header .card-info .card-name {
  color: var(--color-active);
}

.card-date {
  border-radius: 5px !important;
  text-align: center;
}

.data-card .card-data {
  width: 100%;
  padding: 0 1rem;
}

.data-card .card-line {
  flex-grow: 1;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  border: 1px solid #EEEEEE;
  width: 100%;
}

.data-card .card-bottom {
  margin-top: auto;
  display: flex;
  padding: 0 1rem 0.5rem 1rem;
  justify-content: space-between;
  align-self: flex-end;
}

.data-card .card-bottom .info {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #FC7318;
}

.data-card .actions {
  align-items: center;
  font-weight: 400;
  font-size: 12px;
}

.data-card div img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 1.2rem;
}

.data-card div.details {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0rem 1.5rem;
}

.data-card div.details div.title-wrapper {
  width: 100%;
  line-height: 30px;
  padding: 5px 0;
  position: relative;
}

.data-card div.details .title-wrapper h3 {
  margin-bottom: 0.2rem;
  color: var(--color-active);
  font-weight: 700;
  font-size: 1.2em;

  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.data-card div.details p {
  margin-bottom: 0.2rem;
  color: var(--color-secondary);
}

.data-card div button,
.data-card div a {
  border: none;
  background-color: transparent;
  font-size: 1.2em;
  color: var(--color-secondary);
  cursor: pointer;
}

.data-card div button:first-child,
.data-card div a:first-child {
  margin-right: 0.2rem;
}