.media-form .upload-image-preview {
    position: relative;
    width: 100%;
  }
  
  .media-form .upload-image-preview > img,
  .media-form .upload-image-preview > video {
    width: 100%;
    border-radius: 10px;
    height: 140px;
    object-fit: contain;
    border: 1px solid var(--color-border);
    background-color: var(--color-input);
  }
  
  .media-form .upload-image-preview > .btn-remove {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: var(--color-bg-pink);
    border: none;
    color: #fff;
    cursor: pointer;
    border-radius: 0.25rem;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  
  .media-form .upload-drag-and-drop {
    padding: 1.5rem;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid var(--color-border);
    background-color: var(--color-input);
  }
  
  .ant-upload {
    width: 100%;
  }
  
  .media-form .upload-drag-and-drop > i {
    font-size: 1.5em;
    margin-bottom: 1rem;
    display: inline-block;
    color: var(--color-secondary);
  }
  
  .media-form .upload-drag-and-drop > p {
    margin: 0px;
    padding: 0px;
    font-weight: 500;
    color: var(--color-secondary);
  }
  
  .media-form .upload-drag-and-drop:hover,
  .media-form .upload-drag-and-drop:hover > i,
  .media-form .upload-drag-and-drop:hover > p,
  .media-form .upload-drag-and-drop.active,
  .media-form .upload-drag-and-drop.active > i,
  .media-form .upload-drag-and-drop.active > p {
    border-color: var(--color-input-focus);
    /* color: var(--color-active); */
  }