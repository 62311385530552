.delete{
  &__modal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.2rem;

    img{
      margin-bottom: 2rem;
    }

    h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__modal-footer{
    display: flex;
    flex: 1 1;
    justify-content: stretch;
    align-content: stretch;

    button{
      width: 100%;
      margin: 0rem 0.5rem 0rem 0.5rem;
      padding: 0.6rem 0rem 0.6rem 0rem;
    }
  }
}


