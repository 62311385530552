.map {
  padding: 2% 3% !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
}

.map .map-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--color-stroke);
  padding: 1rem 0px;
}

.map .map-header > .button-primary {
  background-color: var(--color-primary);
  border: none;
  color: #fff;
  padding: 0px 1rem;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map .map-header > h1 {
  font-size: 1.5em;
  font-weight: 600;
  color: var(--color-active);
}

.map .map-breadcrumb {
  margin: 1rem 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.map .map-breadcrumb > ul > li > a {
  color: var(--color-active);
}

.map .map-breadcrumb > ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.map .map-breadcrumb > ul > li {
  display: inline-block;
  color: var(--color-secondary);
  margin-right: 1rem;
}

.map .map-breadcrumb > ul > li:not(:last-child)::after {
  content: '/';
  margin-left: 1rem;
  color: var(--color);
}

.map .map-content {
  margin: 1.5rem 0px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.map .map-breadcrumb .button-secondary {
  background-color: var(--color-primary);
  border: none;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map .map-breadcrumb .button-secondary > i {
  margin-right: 0.5rem;
}

.map .map-content .form {
  width: 300px;
  margin-bottom: 2rem;
}

.map .map-content .form label {
  color: var(--color-active);
  margin-bottom: 0.5rem;
  display: inline-block;
}

.map .map-toolbar {
  margin-bottom: 1rem;
}

.map .map-container {
  margin: 0 auto;
}

.map .map-toolbar button {
  border: 1px solid var(--color-stroke);
  color: var(--color-black);
  background-color: #fff;
  padding: 0.25rem 1rem;
  cursor: pointer;
}

.map .map-toolbar button:hover {
  color: var(--color-primary);
}

.map .map-toolbar button:not(:first-child) {
  border-left: none;
}

.map .map-toolbar button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.map .map-toolbar button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.map .map-toolbar button > i {
  margin-right: 0.25rem;
}

.map .map-toolbar button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.map .map-canvas-container {
  cursor: grab;
  overflow: auto;
  width: 100%;
  /* height: 400px; */
  height: calc(100vh - 7.75rem);
  overflow: scroll;
}

.map .inline {
  display: inline-block;
}

.ml-1 {
  margin-left: 1rem;
}

.map .button-wrapper {
  margin-top: 2rem;
}

.map .button-wrapper .button-primary {
  margin-right: 1rem;
  background-color: var(--color-primary);
  border: none;
  color: #fff;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}
