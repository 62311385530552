.screeninfo {
  padding: 2rem;
  font-family: 'Inter', sans-serif;
  width: 100%;
}

.ant-dropdown-menu-title-content {
  font-family: 'Inter', sans-serif !important;
  color: var(--color-active) !important;
}

.screeninfo .screeninfo-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* .screeninfo h3.title {
  font-size: 1.2em;
  color: var(--color-active);
  margin-bottom: 2.5rem;
} */
.screeninfo .screeninfo-nav {
  margin-bottom: 2.5rem;
}

.screeninfo .screeninfo-nav .title {
  font-size: 1.2em;
  color: var(--color-active);
}

.screeninfo .screeninfo-nav span {
  font-size: 1em;
  color: var(--color-active);
}

.screeninfo .ant-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap {
  width: 100% !important;
  display: flex;
}

.screeninfo .ant-tabs-nav-wrap > .ant-tabs-nav-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.screeninfo .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab {
  /* color: var(--color-secondary); */
  /* margin-right: 6rem; */
  /* color: --var(--color-light-grey); */
  color: var(--color-grey);
  width: 100%;
  margin-right: 0.1rem;
  justify-content: center;
}

.screeninfo
  .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  > .ant-tabs-tab-btn,
.screeninfo .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab:hover {
  color: var(--color-active) !important;
}

.screeninfo .ant-tabs-ink-bar {
  background-color: var(--color-active);
  /* min-width: 180px !important; */
  width: 100%;
}

.screeninfo .tab-content {
  margin: 0.5rem 0px;
}

.adsign-tab > .ant-tabs-nav {
  padding: 0px 2rem !important;
}