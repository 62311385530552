.profile {
  font-family: 'Inter', sans-serif !important;
  padding: 2% 3%;
  width: 100%;
  overflow: hidden;

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .page-title{
      margin-right: 1rem;
    }
  }

  &__line {
    width: 100%;
    border-top: 1px solid hsla(0, 0%, 90%, 1);
  }

  &__breadcrumb {
    color: var(--color-active) !important;
    font-family: 'Inter', sans-serif !important;

    &--active {
      color: hsla(206, 19%, 74%, 1) !important;
    }
  }

  &__content {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
  }

  &__left {
    column-gap: 7rem;
    width: 50%;
  }

  &__form {
    margin: 2rem 0rem;
  }

  &__label {
    margin-bottom: 1.175rem;
    display: flex;
    align-items: center;
    color: #22496d;
    font-weight: 600;
  }

  &__form-control {
    margin-bottom: 1.5rem;
    

    .input{
      padding: 1.2rem;
    }
  }

  &__info {
    margin-bottom: 1.875rem;
    margin-top: 4rem;
    display: flex;
    justify-content: flex-end;

    // .button-primary {
    //   background-color: var(--color-primary);
    //   color: #fff;
    //   border: none;
    //   padding: 0.5rem 1rem;
    //   border-radius: 5px;
    //   cursor: pointer;
    // }

    .button-loading {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &-title {
      font-weight: 500 !important;
      font-size: 1rem !important;
      color: hsla(0, 0%, 31%, 1) !important;
    }
    &-desc {
      font-weight: 400 !important;
      font-size: 0.8rem !important;
      color: hsla(206, 19%, 74%, 1) !important;
    }
  }

  &__picture {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;

    &-text {
      font-size: 0.8rem;
      font-weight: 500;
      color: hsla(0, 0%, 31%, 1) !important;
    }

    &-img {
      border-radius: 50% !important;
      width: 5rem !important;
      height: auto !important;
    }
  }

  &__right {
    flex: 1 1 0;
  }
}

.info-message {
  margin-left: 0.5rem;
  color: var(--color-secondary);
  cursor: help;
}
