.justify-left {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.button-delete {
  color: var(--color-danger);
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.button-delete:hover {
  opacity: 0.8;
}

.adsign-table .ant-empty {
  padding: 2rem 0px !important;
}

.adsign-table .ant-table-thead > tr > th.ant-table-cell,
.adsign-table .ant-table-body > tr > td.ant-table-cell  {
  color: var(--color-table-header);
  text-transform: uppercase !important;
  font-weight: 500 !important;
  font-family: 'Inter', sans-serif !important;
  font-size: .8rem !important;
  border-color: var(--color-border-table) !important;
}

.adsign-table .ant-table-thead > tr > th.ant-table-cell:not(:last-child),
.adsign-table .ant-table-tbody > tr > td.ant-table-cell:not(:last-child) {
  border-right: none !important;
}

.config-variable-header {
  justify-content: space-between !important;
}

.config-variable-header .searchbar-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.config-variable-header  .searchbar {
  display: flex;
  align-items: center;
  width: max-content;
  border-radius: 0.35rem;
  border: 1px solid var(--color-border);
  padding: 0.5rem 0.7rem;
  background-color: #fff;
  margin: 0px;
}

.config-variable-header .searchbar > input.search-input {
  border: none;
  background-color: transparent;
  font-family: 'Inter', sans-serif;
  width: 200px;
  color: var(--color-black);
  padding: 0px;
}

.config-variable-header .searchbar > i {
  font-size: 1.5em;
  color: var(--color-text-placeholder);
}

.config-variable-header .searchbar > input.search-input::placeholder {
  color: var(--color-text-placeholder);
}

.config-variable-header .searchbar > input.search-input:focus {
  outline: none;
}

button.btn {
  color: var(--action-color) !important;
  display: flex;
  align-items: center;
}

button.btn > svg {
  margin-right: .5rem;
}

.button-wrapper {
  justify-content: flex-start !important;
  display: flex;
  align-items: center;
}

.ant-pagination {
  padding-right: 2rem !important;
}