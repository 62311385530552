.user {
  padding: 2% 3%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
}

.user .user-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--color-stroke);
  padding: 1rem 0px;
}

.user .user-header > .button-primary {
  background-color: var(--color-primary);
  border: none;
  color: #fff;
  padding: 0px 1rem;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user .user-header > h1 {
  font-size: 1.5em;
  font-weight: 600;
  color: var(--color-active);
}

.user .user-breadcrumb {
  margin: 1rem 0px;
}

.user .user-breadcrumb > ul > li > a {
  color: var(--color-active);
}

.user .user-breadcrumb > ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.user .user-breadcrumb > ul > li {
  display: inline-block;
  color: var(--color-secondary);
  margin-right: 1rem;
}

.user .user-breadcrumb > ul > li:not(:last-child)::after {
  content: '/';
  margin-left: 1rem;
  color: var(--color);
}

.user .user-management-content {
  margin: 1.5rem 0px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.user .user-management-content > .user-card {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 25%;
  padding: 2%;
  text-align: center;
  width: 22%;
  border-radius: 10px;
  position: relative;
}

.user .user-management-content > .user-card:not(:last-child) {
  margin-right: 3rem;
}

.user .user-management-content .user-access-wrapper .user-access {
  display: flex;
  border-bottom: 1px solid var(--color-stroke);
  padding-bottom: 1rem;
}

.user .user-management-content .user-access-wrapper {
  width: 100%;
  margin: 2rem 0px;
}
.user
  .user-management-content
  .user-access-wrapper
  .user-access
  .user-access-organization-title {
  display: block;
  width: 30%;
}

.user .user-management-content .user-access-wrapper .user-access h3 {
  margin: 0px;
  color: var(--color-active);
  font-size: 1.2em;
}

.user
  .user-management-content
  .user-access-wrapper
  .user-access
  .user-access-option {
  display: flex;
  width: 70%;
  justify-content: space-between;
}
.user
  .user-management-content
  .user-access-wrapper
  .user-access
  .user-access-option
  p {
  text-align: center;
}

.user
  .user-management-content
  .user-access-wrapper
  .user-access
  .user-access-option
  div {
  width: 100%;
}

.user
  .user-management-content
  .user-access-wrapper
  .user-access
  .user-access-option
  > p {
  margin: 0px;
  color: var(--color-active);
  font-weight: 500;
}

.user .user-management-content .user-access-wrapper .user-access-sites {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0px;
  color: var(--color-black);
}

.user
  .user-management-content
  .user-access-wrapper
  .user-access-screen
  .user-access-screen-row {
  display: flex;
}

.user
  .user-management-content
  .user-access-wrapper
  .user-access-screen
  .user-access-screen-row
  .user-screen-name {
  width: 30%;
}

.user
  .user-management-content
  .user-access-wrapper
  .user-access-sites
  .user-access-selection {
  display: flex;
  width: 70%;
  justify-content: space-between;
}

.user
  .user-management-content
  .user-access-wrapper
  .user-access-sites
  .user-access-selection
  > div {
  /* width: 13rem; */
  display: flex;
  width: 100%;
  /* justify-content: flex-end; */
  justify-content: center;
}

.user .button-wrapper {
  margin-top: 2rem;
}

.user .button-wrapper .button-primary {
  margin-right: 1rem;
  background-color: var(--color-primary);
  border: none;
  color: #fff;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

.user .button-wrapper .button-secondary {
  margin-right: 1rem;
  background-color: #fff;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}
