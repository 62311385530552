/* The message box is shown when the user clicks on the password field */
.message {
  background: #ffffff !important;
  color: #000;
  position: relative;
  padding: 20px;
  margin-top: 10px;
}

.message p {
  padding: 5px 35px 0px;
  font-size: 18px;
}

.message .icon {
  margin-bottom: -5px;
  margin-right: 20px;
}

.change-password {
 
  font-family: 'Inter', sans-serif !important;
  padding: 2% 3%;
  width: 100%;
  overflow: hidden;
  &__title {
    color: hsla(0, 0%, 31%, 1) !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    margin-bottom: 0.6875rem !important;
  }

  &__desc {
    color: var(--color-secondary);
  }

  &__form {
    margin: 2rem 0rem;
  }

  &__label {
    margin-bottom: 1.175rem;
    display: inline-block;
    color: hsla(209, 53%, 28%, 1);
    font-weight: 600;
  }

  &__form-controls {
    margin-bottom: 1.5rem;
   .ant-input-affix-wrapper{
    padding: 0.8rem;
   }
  }

  &__button {
    // Replace the current button style
    background-color: var(--color-primary) !important;
    color: #fff !important;
    font-size: 1em !important;
    width: 100% !important;
    padding: 1.2rem 0px !important;
    border-radius: 8px !important;
    font-weight: bold !important;
  }

  &__go-back {
    // Replace the current button style
    background-color: #fff !important;
    color: var(--color-primary) !important;
    font-size: 1em !important;
    margin-top: 1rem !important;
    width: 100% !important;
    padding: 1.2rem 0 !important;
    border-radius: 8px !important;
    font-weight: bold !important;
  }

  &__message {
    margin-bottom: 1.5rem;
  }

  &__message-title {
    margin-bottom: 1rem !important;
    font-size: 0.8rem !important;
    font-weight: 500 !important;
    color: var(--color-active) !important;
  }

  &__message-invalid {
    font-weight: 400;
    display: flex;
    align-items: center;

    i {
      margin-right: 0.6875rem;
    }
  }

  &__submit{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 3rem;
    button{
      padding: 0.8rem;
    }
  }
}
