$name-color: #c6538c;
$desc-color: #171C26;
$time-color: #464F60;
$action-color: #A0ACB7;
$table-header-color: rgba(247, 249, 252, 0.8);
$primary-color: #64D5D2;
$btn-action: #A0ACB7;


.button-primary-header {
  border: none;
  height: 100%;
  padding: 8px 20px;
  color: #fff;
  background-color: $primary-color;
  border-radius: 5px;
}



.button-primary-outline-header {
  background-color: #fff;
  border: 1px solid var(--color-primary);
  border-radius: 10px;
  color: var(--color-primary);
  padding: 8px 20px;
  display: flex;
  align-items: center;
  font-weight: 700;
  cursor: pointer;
  font-weight: 700;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0px;
  border-bottom: 1px solid var(--color-stroke);


}

.title {
  font-size: 1.5em !important;
  font-weight: 600 !important;
  color: var(--color-active) !important;
}

.nav {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
}

.search-bar {
  width: 271px;
  margin-right: 0.5rem;
  border: 1px solid var(--color-stroke);
  padding: 0.4rem 1rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: black !important;
  background-color: white;

  input {
    padding-left: 0.5rem;
    border: none;
    background-color: transparent;
    width: 250px;
    outline: none;
  }
  i {
    font-size: 1.5em;
    color: #B3B3B3;
  }
}

.search {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--color-border);
  padding: 10px 20px !important;
  border-radius: 5px;
  font-size: 14px !important;

  i {
    font-size: 1rem;
  }

  input {
    outline: none;
    border: none;
    background-color: transparent;
    padding-left: 1rem;
    color: var(--color-active);

    &::placeholder {
      color: var(--color-secondary);
    }
  }
}

.breadcrumb {
  padding: 0.5rem 2rem 0.5rem 1rem;
  color: var(--color-secondary);
  cursor: pointer;
}

.table {
  th {
    background-color: var(--color-navbar) !important;
    color: var(--color-black);
  }

}

.actions {
  display: flex;
  justify-content: center;
  column-gap: 0.5rem;
}

.btn-text {
  margin-left: 0.5em;
  margin-top: -0.3em;
}

.btn {
  color: $btn-action;
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;

  &:hover {
    cursor: pointer;
  }

  &--edit {
    &:hover {
      color: $primary-color;
    }
  }

  &--delete {
    &:hover {
      color: red;
    }
  }
}