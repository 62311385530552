.user-form {
  width: 100%;
  display: flex;
  justify-content: center;
}

.user-form .form-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* FORM WRAPPER FOR EDIT USER*/
.user-form .form-wrapper-edit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}

.user-form .form-wrapper-edit > div:first-child {
  width: 100%;
  margin-right: 1rem;
}

.user-form .form-wrapper-edit > div:last-child {
  width: 100%;
  margin-left: 1rem;
}

.user-form .form-wrapper-edit .form > .form-label {
  margin-bottom: 0.5rem;
  display: inline-block;
  font-weight: 600;
  color: var(--color-black);
  width: 100%;
}
.user-form .form-wrapper-edit .form > .ant-input {
  padding: 0.6rem 1rem;
}
/* .user-form .form-wrapper-edit .form > .ant-input-password {
  padding: 0.6rem 1rem;
} */
.user-form .form-wrapper-edit .form  .ant-select {
  display: inline-block;
}

.user-form .form-wrapper-edit .form  .ant-select-selector {
  display: inline-flex;
  height: 3rem !important;
  padding: 0.6rem 1rem !important;
}

.user-form .form-wrapper-edit .form {
  margin-bottom: 2rem !important;
}

.user-form .form-wrapper-edit .form .span-email{
  margin-right: 0.5rem;
  width: 100%;
}

.user-form .form-wrapper-edit .form .btn {
  color: hsla(0, 0%, 31%, 1);
  background-color: transparent;
  outline: none;
  border: none;
  
}

/* FORM WRAPPER FOR ADD USER*/

.user-form .form-wrapper > div:first-child {
  width: 100%;
  margin-right: 1rem;
}

.user-form .form-wrapper > div:last-child {
  width: 100%;
  margin-left: 1rem;
}

.user-form .form-wrapper .form > .form-label {
  margin-bottom: 0.5rem;
  display: inline-block;
  font-weight: 600;
  color: var(--color-black);
  width: 100%;
}
.user-form .form-wrapper .form > .ant-input {
  padding: 0.6rem 1rem;
}
.user-form .form-wrapper .form  .ant-input-password > .ant-input{
  padding: 0.4rem !important;
}
.user-form .form-wrapper .form  .ant-select {
  display: inline-block;
}

.user-form .form-wrapper .form  .ant-select-selector {
  display: inline-flex;
  height: 3rem !important;
  padding: 0.6rem 1rem !important;
}

.user-form .form-wrapper .form {
  margin-bottom: 2rem !important;
}

.user-form .form-wrapper .form .span-email{
  margin-right: 0.5rem;
  width: 100%;
}

.user-form .form-wrapper .form .btn {
  color: hsla(0, 0%, 31%, 1);
  background-color: transparent;
  outline: none;
  border: none;
  
}

.user-form .form  .ant-checkbox-wrapper >span{
  color: var(--color-light-blue);
  font-weight: 700;
  
}

.user-form .form  .ant-checkbox-input{
  color: var(--color-secondary);

}


.user-form .upload-image-preview {
  position: relative;
  width: 140px;
}

.user-form .upload-image-preview > img {
  width: 140px;
  border: 2px dashed var(--color-secondary);
  border-radius: 10px;
}

.user-form .upload-image-preview > .btn-remove {
  position: absolute;
  right: -5px;
  top: -5px;
  background-color: var(--color-danger);
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-form .upload-drag-and-drop {
  background-color: #fff;
  padding: 1.5rem;
  width: 140px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 2px dashed var(--color-secondary);
  border-radius: 10px;
  cursor: pointer;
}

.user-form .upload-drag-and-drop > i {
  font-size: 1.5em;
  margin-bottom: 1rem;
  display: inline-block;
  color: var(--color-secondary);
}

.user-form .upload-drag-and-drop > p {
  margin: 0px;
  padding: 0px;
  font-weight: 500;
  color: var(--color-secondary);
}

.user-form .upload-drag-and-drop:hover,
.user-form .upload-drag-and-drop:hover > i,
.user-form .upload-drag-and-drop:hover > p,
.user-form .upload-drag-and-drop.active,
.user-form .upload-drag-and-drop.active > i,
.user-form .upload-drag-and-drop.active > p {
  border-color: var(--color-active);
  color: var(--color-active);
}

.user-form .button-wrapper {
  margin-top: 2rem;
  display: flex;
  flex-direction: row-reverse;

}

.user-form .button-wrapper .button-primary {
  background-color: var(--color-primary);
  border: none;
  color: #fff;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

.user-form .button-wrapper .button-secondary {
  margin-right: 1rem;
  background-color: #fff;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

.user-form .form-wrapper .user-form-message-title {
  margin-bottom: 1rem;
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--color-active);
}

.user-form .form-wrapper .user-form-message-invalid {
  font-weight: 400;
  display: flex;
  align-items: center;
}

.user-form .form-wrapper .user-form-message-invalid i {
  margin-right: 0.6875rem;
}
