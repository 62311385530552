.screens {
  font-family: 'Inter', sans-serif;
  &__header {
    display: flex;
    justify-content: space-between;

    .button {
      border: none;
      height: 100%;
      padding: 0.5rem 1.5rem;
      color: #fff;
      background-color: var(--color-primary);
      border-radius: 5px;
    }
  }

  &__title {
    color: var(--color-active) !important;
    font-weight: 600 !important;
    font-size: 1.5em !important;
  }

  &__line {
    width: 100%;
    border-top: 1px solid hsla(0, 0%, 90%, 1);
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.2rem;
  }

  &__modal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.2rem;

    img{
      margin-bottom: 2rem;
    }

    h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__modal-footer{
    display: flex;
    flex: 1 1;
    justify-content: stretch;
    align-content: stretch;

    button{
      width: 100%;
      margin: 0rem 0.5rem 0rem 0.5rem;
      padding: 0.6rem 0rem 0.6rem 0rem;
    }
  }

  &__search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 15%;
    border-radius: 0.5rem;
    border: 1px solid var(--color-secondary);
    padding: 0.3rem 0.7rem;
    margin-right: 1rem;

    i {
      font-size: 1rem;
    }

    input {
      outline: none;
      border: none;
      background-color: transparent;
      padding-left: 1rem;
      color: var(--color-active);

      &::placeholder {
        color: var(--color-secondary);
      }
    }
  }

  &__button-group{
    display: flex;
    flex-direction: row;
  }



  &__breadcrumb {
    font-size: 1rem !important;
    font-family: 'Inter', sans-serif !important;
  }

  &__table {
    th {
      background-color: var(--color-navbar) !important;
      color: var(--color-black);
    }
  }

  &__actions {
    display: flex;
    column-gap: 0.5rem;
  }

  .btn {
    color: hsla(0, 0%, 31%, 1);
    background-color: transparent;
    outline: none;
    border: none;

    &:hover {
      cursor: pointer;
    }

    &--edit {
      &:hover {
        color: var(--color-primary);
      }
    }

    &--delete {
      &:hover {
        color: red;
      }
    }
  }
}
