.mt-1 {
  margin-top: 1rem;
}

.form-adsign .ant-select-selector {
  width: 100%;
  background-color: var(--color-input) !important;
  border: 1px solid var(--color-border) !important;
  border-radius: 0.25rem !important;
  font-family: 'Inter', sans-serif;
  font-size: .9rem;
  padding: 0.15rem 1rem !important;
  height: auto !important;
}

.form-adsign .ant-select-open .ant-select-selector, .form-adsign .ant-select-focused .ant-select-selector {
  border-color: var(--color-input-focus) !important;
  box-shadow: none !important;
}

h2.page-title {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 2rem;
}