.configuration {
  padding: 2rem;
  font-family: 'Inter', sans-serif;
  width: 100%;
}

.ant-dropdown-menu-title-content {
  font-family: 'Inter', sans-serif !important;
  color: var(--color-active) !important;
}

.configuration .configuration-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 1rem;
}
.configuration .configuration-nav >svg {
  width: 1.5rem;
  height: 1.5rem;
}

.information-box {
  border-radius: 6px;
background:  #F0F3FF;
display: flex;
flex-direction: column;
padding: 0.75rem;
  background-image: url('../../assets/icon/info-background.svg');
  background-position: right top;
  background-repeat: no-repeat;
  margin-bottom: 0.75rem;
}
.information-box .title-row{
display: flex;
flex-direction: row;
color: var(--color-text-primary);
font-weight: 600;
align-items: center;

}
.information-box .title-row > svg{
margin-right: 0.5rem;

}
.information-box .information-content {
  padding: 0 1.5rem;

}
.information-box .information-content > ul{
list-style: disc inside ;

}

.confirm-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.confirm-modal-content > h1 {
  font-weight : bold;
  color       : black;
  font-size: 1.25rem;
  margin: 1rem 0;
  min-width   : 5.7em;
}
.confirm-modal-content > p {
  text-align: center;
  margin-bottom: 1rem;
}


.configuration .configuration-nav .title {
  font-size: 1.2em;
  color: var(--color-active);
}

.configuration .configuration-nav span {
  font-size: 1em;
  color: var(--color-text-black);
}

.configuration .ant-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap {
  width: 100% !important;
  display: flex;
}

.configuration .ant-tabs-nav-wrap > .ant-tabs-nav-list {
  width: 100%;
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-start;
}

.configuration .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab {
  /* color: var(--color-secondary); */
  /* margin-right: 6rem; */
  /* color: --var(--color-light-grey); */
  color: var(--color-grey);
  width: 100%;
  margin-right: 0.1rem;
  justify-content: flex-start;
}

.configuration
  .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active {
    background: var( #DBF5F5);
  }

.configuration
  .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  > .ant-tabs-tab-btn,
.configuration .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab:hover {
  color: var(--color-active) !important;

}
.configuration
  .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab
  > .ant-tabs-tab-btn {
  width: 100%;
}

.configuration .ant-tabs-ink-bar {
  background-color: var(--color-primary);
  /* min-width: 180px !important; */
  width: 100%;
}

.configuration .tab-content {
  margin: 0.5rem 0px;
}

