.role-form {
  width: 100%;
}

.role-form .form-wrapper {
  /* display: flex; */
  flex-direction: row;
  width: 100%;
}

.role-form .form-wrapper > div:first-child {
  width: 100%;
  margin-right: 1rem;
}

.role-form .form-wrapper > div:last-child {
  width: 100%;
  margin-left: 1rem;
}
.button-reset{
  margin-left: 1rem;
}

.role-form .form-wrapper .form > .form-label {
  margin-bottom: 0.5rem;
  display: inline-block;
  color: var(--color-black);
  width: 100%;
}

.role-form .form-wrapper .form {
  margin-bottom: 2rem !important;
}

.role-form .form-wrapper .form .span-email{
  margin-right: 0.5rem;
  width: 100%;
}

.role-form .form-wrapper .form .btn {
  color: hsla(0, 0%, 31%, 1);
  background-color: transparent;
  outline: none;
  border: none;
  
}

.role-form .upload-image-preview {
  position: relative;
  width: 140px;
}

.role-form .upload-image-preview > img {
  width: 140px;
  border: 2px dashed var(--color-secondary);
  border-radius: 10px;
}

.role-form .upload-image-preview > .btn-remove {
  position: absolute;
  right: -5px;
  top: -5px;
  background-color: var(--color-danger);
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.role-form .button-wrapper {
  margin-top: 2rem;
}

.role-form .button-wrapper .button-primary {
  /* margin-right: 1rem; */
  background-color: var(--color-primary);
  border: none;
  color: #fff;
  /* padding: 0.5rem 1.5rem; */
  border-radius: 5px;
  cursor: pointer;
}

.role-form .button-wrapper .button-secondary {
  margin-right: 1rem;
  background-color: #fff;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

.role-form .form-wrapper .role-form-message-title {
  margin-bottom: 1rem;
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--color-active);
}

.role-form .form-wrapper .role-form-message-invalid {
  font-weight: 400;
  display: flex;
  align-items: center;
}

.role-form .form-wrapper .role-form-message-invalid i {
  margin-right: 0.6875rem;
}

.role-access {
  width: 100%;
  display: flex;
  align-content: flex-start;
}
.role-access .column {
  flex: auto;
}
.role-access .column .access-group {
  margin-bottom: 2rem;
}
.role-access .column .title-point {
  color:  var(--color-primary);
  height: 40px;
  font-size: large;
  display: flex;
}
.role-access .column .title-point .sub-point {
  color:  var(--color-primary);
  margin-right: 10px;
  font-size: large;
}
.role-access .column .point {
  color:  var(--color-primary);
  height: 30px;
  font-size: large;
}
.role-access .column .sub-point {
  color:  var(--color-primary);
  height: 25px;
  font-size: medium;

}
.btn {
  color: hsla(0, 0%, 31%, 1);
  background-color: transparent;
  outline: none;
  border: none;

 
  
}
