.users {
    font-family: 'Roboto', sans-serif;
    padding: 2% 3%;
    width: 100%;
  
    &__header {
      display: flex;
      justify-content: space-between;
      padding: 1rem 0px;
      border-bottom: 1px solid var(--color-stroke);
  
      .button-primary {
        border: none;
        height: 100%;
        padding: 0.5rem 1.5rem;
        color: #fff;
        background-color: var(--color-primary);
        border-radius: 5px;
      }
    }
  
    &__title {
      font-size: 1.5em !important;
      font-weight: 600 !important;
      color: var(--color-active) !important;
    }
  
    &__nav {
      display: flex;
      justify-content: flex-end;
      margin: 1rem 0;
    }
  
    &__search {
      display: flex;
      align-items: center;
      width: 15%;
      border-radius: 0.5rem;
      border: 1px solid var(--color-secondary);
      padding: 0.3rem 0.7rem;
      margin-right: 1rem;
  
      i {
        font-size: 1rem;
      }
  
      input {
        outline: none;
        border: none;
        background-color: transparent;
        padding-left: 1rem;
        color: var(--color-active);
  
        &::placeholder {
          color: var(--color-secondary);
        }
      }
    }
  
    &__breadcrumb {
      padding: 0.5rem 2rem 0.5rem 1rem;
      color: var(--color-secondary);
      cursor: pointer;
    }
  
    &__table {
      th {
        background-color: var(--color-navbar) !important;
        color: var(--color-black);
      }
    }
  
    &__actions {
      display: flex;
      align-items: center;
      // justify-content: center;
      column-gap: 1rem;
    }
  
    .btn {
      color: hsla(0, 0%, 31%, 1);
      background-color: transparent;
      outline: none;
      border: none;
  
      svg {
        margin-right: 0.2rem;
        border-color: var(--color-light-grey) !important;
      }
  
      &:hover {
        cursor: pointer;
      }
  
      &--edit {
        &:hover {
          color: var(--color-primary);
        }
      }
  
      &--access {
        &:hover {
          color: green;
        }
      }
  
      &--delete {
        &:hover {
          color: red;
        }
      }
    }
  }