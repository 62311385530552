.media-categories {
  .button-primary {
    border: none;
    height: 100%;
    padding: 0.5rem 1.5rem;
    color: #fff;
    background-color: var(--color-primary);
    border-radius: 5px;
  }
  &__nav {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.2rem;
    align-items: flex-start;
  }

  &__search-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
  }

  &__search {
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid var(--color-secondary);
    padding: 0.3rem 0.7rem;
    margin-right: 1rem;

    i {
      font-size: 1.5em;
      color: var(--color-active);
    }

    input {
      outline: none;
      border: none;
      background-color: transparent;
      padding-left: 1rem;
      color: var(--color-active);

      &::placeholder {
        color: var(--color-secondary);
      }
    }
  }

  &__table {
    th {
      background-color: var(--color-navbar) !important;
      color: var(--color-black);
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    column-gap: 0.5rem;
  }

  .btn {
    color: hsla(0, 0%, 31%, 1);
    background-color: transparent;
    outline: none;
    border: none;

    &:hover {
      cursor: pointer;
    }

    &--edit {
      &:hover {
        color: var(--color-primary);
      }
    }

    &--delete {
      &:hover {
        color: red;
      }
    }
  }
}
