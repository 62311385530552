.files-management {
  .button-primary {
    border          : none;
    height          : 100%;
    padding         : 0.5rem 1.5rem;
    color           : #fff;
    background-color: var(--color-primary);
    border-radius   : 5px;
  }

  .button-secondary {
    margin-right    : 1rem;
    background-color: #fff;
    border          : 1px solid var(--color-primary);
    color           : var(--color-primary);
    height          : 100%;
    padding         : 0.5rem 1.5rem;
    border-radius   : 5px;
  }


  &__nav {
    display        : flex;
    align-items    : flex-start;
    justify-content: flex-end;
    margin-bottom  : 1.2rem;
  }

  &__search-wrapper {
    margin-right: 1rem;
  }

  &__search {
    display      : flex;
    align-items  : center;
    border-radius: 0.5rem;
    border       : 1px solid var(--color-secondary);
    padding      : 0.3rem 0.7rem;

    i {
      font-size: 1.5em;
      color    : var(--color-active);
    }

    input {
      outline         : none;
      border          : none;
      background-color: transparent;
      padding-left    : 1rem;
      color           : var(--color-active);

      &::placeholder {
        color: var(--color-secondary);
      }
    }
  }

  &__table {
    margin-top: 1.2rem;

    th {
      background-color: var(--color-navbar) !important;
      color           : var(--color-black);
    }
  }

  &__actions {
    display    : flex;
    align-items: center;
  }

  .btn {
    color           : hsla(0, 0%, 31%, 1);
    background-color: transparent;
    outline         : none;
    border          : none;
    height          : 100%;
    align-self      : center;

    &:not(:last-child) {
      margin-right: 0.3rem;
    }

    &--edit {
      &:hover {
        color: var(--color-primary);
      }
    }

    &--delete {
      &:hover {
        color: hsl(0, 100%, 50%);
      }
    }
  }

  .upload-image-preview {
    position: relative;
    width: 140px;
  }
  
  .upload-image-preview > img,
  .upload-image-preview > video {
    width: 140px;
    border: 2px dashed var(--color-secondary);
    border-radius: 10px;
    min-height: 100px;
  }
  
  .upload-image-preview > .btn-remove {
    position: absolute;
    right: -5px;
    top: -5px;
    background-color: var(--color-danger);
    border: none;
    color: #fff;
    cursor: pointer;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  
  .upload-drag-and-drop {
    background-color: #fff;
    padding: 1.5rem;
    width: 230px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 2px dashed var(--color-secondary);
    border-radius: 10px;
    cursor: pointer;
  }
  
  .upload-drag-and-drop > i {
    font-size: 1.5em;
    margin-bottom: 1rem;
    display: inline-block;
    color: var(--color-secondary);
  }
  
  .upload-drag-and-drop > p {
    margin: 0px;
    padding: 0px;
    font-weight: 500;
    color: var(--color-secondary);
  }
  
  .upload-drag-and-drop:hover,
  .upload-drag-and-drop:hover > i,
  .upload-drag-and-drop:hover > p,
  .upload-drag-and-drop.active,
  .upload-drag-and-drop.active > i,
  .upload-drag-and-drop.active > p {
    border-color: var(--color-active);
    color: var(--color-active);
  }
}
