.maps {
  .button-primary {
    border: none;
    height: 100%;
    padding: 0.5rem 1.5rem;
    color: #fff;
    background-color: var(--color-primary);
    border-radius: 5px;
  }
  &__nav {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.2rem;
    align-items: flex-start;
  }

  &__search-wrapper {
    margin-right: 1rem;
  }

  &__search {
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid var(--color-secondary);
    padding: 0.3rem 0.7rem;

    i {
      font-size: 1.5em;
      color: var(--color-active);
    }

    input {
      outline: none;
      border: none;
      background-color: transparent;
      padding-left: 1rem;
      color: var(--color-active);

      &::placeholder {
        color: var(--color-secondary);
      }
    }
  }

  &__list {
    display: flex;
    justify-content: flex-start;
  }

  &__card {
    margin: 0.5rem 0px;
    width: 24%;
    position: relative;
    cursor: pointer;
  }

  &__card:hover {
    .maps__overlay {
      opacity: 1;
    }
  }

  &__card:not(:last-child) {
    margin-right: 1rem;
  }

  &__img {
    width: 100%;
    height: 400px;
    object-fit: contain;
  }

  &__overlay {
    position: absolute;
    background-color: #21486cc9;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1rem;
    opacity: 0;
    display: flex;
    transition: all 0.3s ease-in-out;

    &-button-group {
      display: flex;
      width: 100%;
      justify-content: center;
      button,
      a {
        cursor: pointer;
        border: none;
        background-color: #fff;
        color: var(--color-active);
        padding: 0.25rem 0.5rem;
        border-radius: 5px;
        display: inline-block;
      }
    }

    &-button-group > button:not(:last-child),
    &-button-group > a:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  &__name {
    color: #fff !important;
    font-size: 1.2em !important;
  }

  &__pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
  }

  &__title {
    font-size: 0.8125rem;
    font-weight: 500;
    color: hsla(0, 0%, 31%, 1);
    pointer-events: none;
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.1rem;
  }

  .btn {
    color: hsla(206, 19%, 74%, 1);
    background-color: transparent;
    box-shadow: none !important;
    outline: none;
    border: none;
    cursor: pointer;

    &--pin {
      text-decoration: none;
      &:hover {
        color: green;
      }
    }

    &--edit {
      text-decoration: none;
      &:hover {
        color: var(--color-primary);
      }
    }

    &--delete {
      &:hover {
        color: red;
      }
    }
  }
}
